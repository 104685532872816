import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  captureException,
  formatCurrency,
  FORMATTED_PARENTHESES_WELLNESS_PHONE_NUMBER,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, LegalBody } from '@pumpkincare/shared/ui';
import { downloadWellnessDoc } from '../../user-service';
import CoverageDates from '../coverage-dates';
import CoverageDocuments from '../coverage-documents';

import styles from './pet-wellness-overview.css';

const PILLS = {
  Active: styles.active,
  'Renewal Declined': styles.renewalDeclined,
  Cancelled: styles.cancelled,
  'Billing Lapsed': styles.billingLapsed,
  Pending: styles.pending,
};
function PetWellnessOverview({
  classes,
  provider,
  entitlements,
  price,
  documents,
  dateConfig,
  petStatus,
}) {
  function handleDocumentClick({ id }) {
    downloadWellnessDoc(id).then(url => {
      if (url) {
        window.open(url);
      } else {
        captureException(`No url returned for wellness ID ${id}`);
      }
    });
  }

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={styles.overview}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <h5>{provider}</h5>
            <LegalBody data-testid={'statusPill'} className={PILLS[petStatus]}>
              {petStatus}
            </LegalBody>
          </div>

          {price.cost ? (
            <LegalBody>
              <b>{formatCurrency(price.cost)}</b> / {price.frequency}
            </LegalBody>
          ) : null}
        </div>

        <div className={styles.entitlements}>
          <div className={styles.entitlementList}>
            {entitlements.map(entitlement => (
              <Body2 key={entitlement.description}>
                {entitlement.description}

                <span className={styles.usage}>
                  {entitlement.isUnlimited ? (
                    'Unlimited'
                  ) : (
                    <>
                      <b>
                        {formatCurrency(entitlement.utilized, {
                          hideZero: true,
                          areCents: true,
                        })}
                      </b>{' '}
                      of{' '}
                      {formatCurrency(entitlement.total, {
                        hideZero: true,
                        areCents: true,
                      })}
                    </>
                  )}
                </span>
              </Body2>
            ))}
          </div>
        </div>

        {documents.length ? (
          <CoverageDocuments
            documents={documents}
            title='Full Benefit Details'
            handleDocumentClick={handleDocumentClick}
          />
        ) : null}
      </div>

      <div className={styles.datesAside}>
        {dateConfig.dates.length ? (
          <CoverageDates
            dates={dateConfig.dates}
            subtitle={dateConfig.subtitle}
            canContact={dateConfig.canContact}
            phone={{
              copy: FORMATTED_PARENTHESES_WELLNESS_PHONE_NUMBER,
              link: WELLNESS_PHONE_NUMBER_LINK,
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

PetWellnessOverview.defaultProps = {
  classes: {},
  price: {},
  documents: [],
  entitlements: [],
  dateConfig: { dates: [] },
  provider: 'Pumpkin Wellness Club',
  petStatus: 'Active',
};

PetWellnessOverview.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  price: PropTypes.shape({
    cost: PropTypes.number,
    frequency: PropTypes.oneOf(['month', 'year']),
  }),

  dateConfig: PropTypes.shape({
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        subtitle: PropTypes.string,
      })
    ).isRequired,
  }),

  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      processingText: PropTypes.string,
    })
  ),

  provider: PropTypes.string,
  entitlements: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      total: PropTypes.number,
      utilized: PropTypes.number,
    })
  ),

  petStatus: PropTypes.string,
};

export default PetWellnessOverview;
